import React, { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import Layout from "components/Layout";
import Routes from "core/Routes";
import { theme } from "uikit/theme";
import { ToastContainer } from "react-toastify";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import AuthContext from "../../contexts/AuthContext";
import ProfileContext from "../../contexts/ProfileContext";
import ScrollWrapper from "../ScrollWrapper";
import TranslationWrapper from "../TranslationWrapper";
import Loader from "../../components/Global/Loader";
import MessagingContext from "contexts/MessagingContext";

import "styles/_main.css";
import "react-toastify/dist/ReactToastify.css";
import { PasswordNotSetDialog } from "../PasswordNotSet/PasswordNotSetDialog";
import { useBrowserUnLoad } from "../hooks/useBrowserUnLoad";
import { AnnouncementProvider } from "../../contexts/AnnouncementProvider";
import { Helmet } from "../../SEO/Helmet";
import { ScriptImporter } from "../Scripts/ScriptImporter";
import { ScriptPages } from "../../constants/scriptsPage";

export const App = () => {
  useBrowserUnLoad();
  const { isLoading, isFeatureEnabled, scripts } = useContext(ConfigurationContext);
  const { user } = useContext(AuthContext);
  const { getUnreadCount } = useContext(MessagingContext);
  const { fetchProfile } = useContext(ProfileContext);
  const [scriptsAdded, setScriptsAdded] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      if (isFeatureEnabled("family_message")) getUnreadCount();
      fetchProfile(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (isFeatureEnabled("family_message") && user) getUnreadCount();
    }, 15000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Chat bot setup
   */
  React.useEffect(() => {
    if (scripts?.length > 0 && !scriptsAdded) {
      setScriptsAdded(true);
      for (let i = 0; i < scripts.length; i++) {
        let scriptBody = scripts[i]?.body;
        const script = document.createElement("script");
        if (/<script[\s\S]*<\/script>/gi.test(scriptBody)) {
          const scriptTagMatches = scriptBody.match(/<script[^>]*>([\s\S]*)<\/script>/i);
          if (scriptTagMatches && scriptTagMatches[1]) {
            script.innerText = scriptTagMatches[1].trim();
          }

          // Check if script has a src attribute within the script tag
          const srcAttributeMatch = scriptBody.match(/<script[^>]*src=["']?([^"'>]+)["']?[^>]*>/i);
          if (srcAttributeMatch && srcAttributeMatch[1]) {
            script.src = srcAttributeMatch[1];
          }
        }
        // Append script to head
        if (scriptBody || script.src) {
          document.head.appendChild(script);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scripts]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <BrowserRouter>
        <ScrollWrapper>
          <ThemeProvider theme={theme}>
            <TranslationWrapper>
              <Helmet />
              <Layout>
                <CssBaseline />
                <Routes />
                <AnnouncementProvider />
                <PasswordNotSetDialog />
                <ScriptImporter page={ScriptPages.all} />
              </Layout>
            </TranslationWrapper>
          </ThemeProvider>
        </ScrollWrapper>
      </BrowserRouter>
      <ToastContainer theme="dark" position="top-center" style={{ fontSize: "0.8em" }} />
    </>
  );
};

export default App;
