import React, { useEffect, useState } from "react";
import apiRequest from "../../api/apiRequest";
import urls from "../../api/urls";
import { preinit } from "react-dom";

export const ScriptImporter = ({ page }) => {
  const [scripts, setScripts] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const response = await apiRequest({
        method: "get",
        url: `${urls.scripts}?page=${page}`,
      });
      setScripts(response);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (scripts && scripts.length) {
      scripts.forEach(script => {
        preinit(script.script, { as: "script" });
      });
    }
  }, [scripts, scripts?.script]);

  return (
    <>
      {scripts && scripts.map(script => <div dangerouslySetInnerHTML={{ __html: script.html }} />)}
    </>
  );
};
