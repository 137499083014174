import { useRenderNode } from "./useRenderNode";
import { useId, Fragment } from "react";

export const CraftJsonRenderer = ({ craftJson }) => {
  const renderNode = useRenderNode();
  const parseCraft = JSON.parse(craftJson);
  const id = useId();

  return <Fragment key={id}>{renderNode(parseCraft["ROOT"], parseCraft)}</Fragment>;
};
